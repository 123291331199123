
import { agent } from "@/agent";
import ExpenseItem from "@/components/ExpenseItem.vue";
import { useStorage } from "@/composables/useStorage";
import { useNetwork } from "@/composables/useNetwork";
import ExpenseModal from "@/components/ExpenseModal.vue";
import {
  IonPage,
  IonHeader,
  IonContent,
  IonCard,
  IonCardContent,
  IonRow,
  IonCol,
  IonList,
  IonLabel,
  IonItemGroup,
  IonItemDivider,
  onIonViewWillEnter,
  IonRefresher,
  IonRefresherContent,
  IonToolbar,
  IonDatetime,
  IonItem,
  IonTitle
} from "@ionic/vue";
import Toolbar from "@/components/Toolbar.vue";
import EditModal from "@/components/EditModal.vue";

import { computed, defineComponent, ref } from "vue";
import { useFeature } from "@/composables/useFeature";
import { isSameMonth, parseISO } from "date-fns";

export default defineComponent({
  components: {
    EditModal,
    ExpenseItem,
    IonPage,
    IonHeader,
    IonContent,
    IonCard,
    IonCardContent,
    IonRow,
    IonCol,
    IonList,
    IonLabel,
    IonItemGroup,
    IonItemDivider,
    IonRefresher,
    IonRefresherContent,
    IonDatetime,
    IonToolbar,
    ExpenseModal,
    Toolbar,
    IonItem,
    IonTitle
  },
  setup() {
    // Constants
    const { getUser } = useStorage();
    const { loading, raiseToast } = useFeature();
    const { getByUser, getById, getAllCategories } = agent();
    const expenseGroups = ref<any>();
    const recentExpenseGroups = ref<any>();
    const { isInternet } = useNetwork();
    const expenseModal = ref(false);
    const editModal = ref(false);
    const selectedExpense = ref();
    const user = ref();
    const categories = ref([]);
    const queryDate = ref("");
    queryDate.value = `${new Date().getFullYear()}-${(new Date().getMonth() < 9
      ? "0"
      : "") +
      (new Date().getMonth() + 1)}`;

    // Methods
    const fetchItems = async () => {
      if (!isInternet.value) {
        return;
      }

      const res = await getByUser(user.value.publicId);

      if (res && res.data) {
        const recent = [];
        for (let i = 0; i < 3; i++) {
          if (res.data[i]) {
            recent.push(res.data[i]);
          }
        }

        recentExpenseGroups.value = recent;
        expenseGroups.value = res.data;
      }
    };

    const handleOpenExpenseModal = async (expense: any) => {
      const loadingOverlay = await loading("Loading expense");
      loadingOverlay.present();

      try {
        const res = await getById(expense.publicId);
        if (res) {
          selectedExpense.value = res.data;
          expenseModal.value = true;
        }
      } catch (e) {
        if (e instanceof Error) {
          raiseToast(e.message, "error");
        }
      } finally {
        loadingOverlay.dismiss();
      }
    };

    const handleEditExpense = () => {
      expenseModal.value = false;
      editModal.value = true;
    };

    const handleCloseExpenseModal = () => {
      if (expenseModal.value === true) {
        expenseModal.value = false;
      }
    };

    const handleCloseEditModal = async (update: boolean, expense: any) => {
      if (editModal.value === true) {
        editModal.value = false;
      }
      if (update) {
        const loadingOverlay = await loading("Please wait. Updating expense");
        loadingOverlay.present();
        const { update } = agent();
        const res = await update(expense);
        await fetchItems().catch();
        if (res.status === 200) {
          await raiseToast("Updated Expense", "success");
        } else {
          await raiseToast("Error: " + res.message, "danger");
        }
        loadingOverlay.dismiss();
      }
    };

    // Computed
    const monthlyExpenseGroups = computed(() => {
      if (expenseGroups.value) {
        return expenseGroups.value.filter((x: any) => {
          return isSameMonth(
            parseISO(x.expenses[0].date),
            new Date(queryDate.value)
          );
        });
      } else {
        return [];
      }
    });

    // Lifecycle
    onIonViewWillEnter(async () => {
      user.value = await getUser();
      const loadingOverlay = await loading("Please wait. Fetching expenses");
      loadingOverlay.present();

      await fetchItems();
      categories.value = (await getAllCategories()).data;

      loadingOverlay.dismiss();
    });
    return {
      categories,
      recentExpenseGroups,
      monthlyExpenseGroups,
      editModal,
      expenseModal,
      queryDate,
      selectedExpense,
      user,
      handleCloseEditModal,
      handleEditExpense,
      handleCloseExpenseModal,
      handleOpenExpenseModal,
      fetchItems
    };
  }
});
